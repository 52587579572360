import React from 'react' // React must be in scope to use JSX
import Launch from '@material-ui/icons/Launch'

export const canonicalURL = "https://jlliew.com";
export const bioLabel = "Jonathan Liew";
export const bioDescription =
    "Hello! This is a space I put together to showcase my work. I'm a designer + artist with a background in games, and I'm passionate about the stories we tell through the experiences we create.";
export const contactInvitation =
    //<p className='bio-contact injury'>I'm recovering from injuries, but feel free to get in touch</p>;  
    "Send me an email if you'd like to get in touch";
export const contactEmail = "design@jlliew.com";

export const siteDescription =
    "This is a space I put together to showcase my work. I'm a designer and illustrator with a background in games, and I'm passionate about the stories we tell through the experiences we create.";

export const standardTileRowCount = 8; /* Adjust the ProjectTiles.css tile width if this changes.*/

export const projectData = [
    {
        projectTitle: "A Poem About a Frog",
        projectSubtitle: "Children's Book Illustration",
        projectDescription: <div>
            <p>My friend Cat had written a poem for her friend, and commissioned me to illustrate it as a children's book she would then give as a gift. The tale follows the journey of a frog trying to find a home with the support of a rainforest band.</p>
            <p>As well as illustrating the book, I managed its editorial design and prepress. The process has been a lovely experience, the feedback more than encouraging – and may be an avenue to pursue further!</p>
        </div>,
        pageDescription: "My friend Cat had written a poem for her friend, and commissioned me to illustrate it as a children's book she would then give as a gift. The tale follows the journey of a frog trying to find its home with the support of a rainforest band.",
        projectThumbnail: "/images/thumbnails/apaaf_thumb.jpg",
        projectURL: "/a-poem-about-a-frog",
        projectTags: ["Photoshop", "InDesign", "Children's Book", "Editorial Design", "Prepress", "Book Publishing", "CMYK"],
        projectImages: [
            {
                imageURL: "/images/apaaf/apaaf_cover_photo_01.jpg",
                imageAlt: "Photograph of cover artwork with titles, depicting frog overlooking lake covered in lily pads, across back and front of cover spread.",
                desktopContain: true,
                mobileContain: false
            },
            {
                imageURL: "/images/apaaf/apaaf_butterfly_artwork_01.jpg",
                imageAlt: "Vertical spread of butterfly guiding frog up ascending tree, colourfully surrounded by smaller butterflies and falling foliage.",
                desktopContain: true,
                mobileContain: true
            },
            {
                imageURL: "/images/apaaf/apaaf_search_photo_01.jpg",
                imageAlt: "Photograph of first spread – a small frog leaps across a dark lake, looks up from a lily pad as rain falls upon it and across a river it hops, searching for something.",
                desktopContain: true,
                mobileContain: false
            },
            {
                imageURL: "/images/apaaf/apaaf_spider_photo_01.jpg",
                imageAlt: "Photograph of physical book spread, depicting spider with large eyes atop webbing and glowing gossamer bokeh lights.",
                desktopContain: true,
                mobileContain: false
            },
            {
                imageURL: "/images/apaaf/apaaf_macaw_artwork_01.jpg",
                imageAlt: "Artwork excerpt of macaw, peeking at viewer, the little frog reflected in its pupil. Surrounding imagery of macaw flying across landscape and interacting with rainforest band members.",
                desktopContain: true,
                mobileContain: true
            },
            {
                imageURL: "/images/apaaf/apaaf_bonanza_photo_01.jpg",
                imageAlt: "Photo of last spread – the rainforest is alive with music as creatures great and small celebrate in the home the frog has created.",
                desktopContain: true,
                mobileContain: false
            },
            {
                imageURL: "/images/apaaf/apaaf_cover_artwork_02.jpg",
                imageAlt: "Cover artwork with titles, depicting frog overlooking lake covered in lily pads.",
                desktopContain: true,
                mobileContain: true
            }
        ]
    },
    {
        projectTitle: "Annalise & Wess' Wedding",
        projectSubtitle: "Pictoria Media",
        projectDescription: <div>
            <p>One of the perks of working in design is being able lend special occasions their personal and well-deserved extravagance. It was a pleasure working with Annalise & Wess to design their wedding stationery – incorporating their green colour palette and twin bird motif, utilising gold foiling, embossing/debossing and more across their invitation suite, entrance banners, place sets, table numbers, and seating chart.</p>
            <p>Photography by <a href="https://www.bensowry.com/" target="_blank" rel="noopener noreferrer">Ben Sowry<Launch className="project-external-link-icon" /></a>.</p>
        </div>,
        pageDescription: "One of the perks of working in design is being able lend special occasions their personal and well-deserved extravagance.",
        projectThumbnail: "/images/thumbnails/pm_aww_thumb.jpg",
        projectURL: "/annalise-wess-wedding",
        projectTags: ["InDesign", "Illustrator", "Event Stationery", "Large Format Print", "Offset Printing", "Gold Foiling", "Embossing & Debossing"],
        projectImages: [
            {
                imageURL: "/images/pm_aww/pm_aww_placesettings_01.jpg",
                imageAlt: "Annalise & Wess Wedding Place Setting",
                desktopContain: false,
                mobileContain: false
            },
            {
                imageURL: "/images/pm_aww/pm_aww_placesettings_06.jpg",
                imageAlt: "Annalise & Wess Wedding Place Setting",
                desktopContain: false,
                mobileContain: false
            },
            {
                imageURL: "/images/pm_aww/pm_aww_placesettings_07.jpg",
                imageAlt: "Annalise & Wess Wedding Place Setting",
                desktopContain: false,
                mobileContain: false
            }
        ]
    },
    {
        projectTitle: "Pictoria Media Website Overhaul",
        projectSubtitle: "Pictoria Media",
        projectDescription: <div>
            <p>Pictoria were in need of a new look for their undeniably dated website. I photographed samples of their existing work and prepared a modular design that would allow the company to proudly showcase their work and capabilities, with an aesthetic that reflected the new kind of work they would like to attract.</p>
        </div>,
        pageDescription: "Pictoria were in need of a new look for their undeniably dated website. I photographed samples of their existing work and prepared a modular design that would allow the company to proudly showcase their work and capabilities, with an aesthetic that reflected the new kind of work they would like to attract.",
        projectThumbnail: "/images/thumbnails/pictoria_website_thumb.jpg",
        projectURL: "/pictoria-website-overhaul",
        projectTags: ["Figma", "Web Design", "Photography", "Wordpress"],
        projectImages: [
            {
                imageURL: "/images/pictoria/pictoria_website_01.jpg",
                imageAlt: "Pictoria Media Website Overhaul",
                desktopContain: false,
                mobileContain: false
            },
            {
                imageURL: "/images/pictoria/pictoria_website_02.jpg",
                imageAlt: "Pictoria Media Website Overhaul",
                desktopContain: false,
                mobileContain: false
            },
            {
                imageURL: "/images/pictoria/pictoria_website_03.jpg",
                imageAlt: "Pictoria Media Land Acknowledgement which reads, 'We acknowledge the Traditional Custodians of the land and waters on which we work and pay our respects to Indigenous Elders past, present and emerging. Sovereignty has never been ceded. It always was and always will be, Aboriginal land.'",
                desktopContain: true,
                mobileContain: true
            },
            {
                imageURL: "/images/pictoria/pictoria_website_04.jpg",
                imageAlt: "Pictoria Media Folio Page Sample",
                desktopContain: false,
                mobileContain: false
            },
            {
                imageURL: "/images/pictoria/pictoria_website_before.jpg",
                imageAlt: "Pictoria Media Website Overhaul Before & After",
                desktopContain: true,
                mobileContain: false
            }
        ]
    },
    {
        projectTitle: "Digital Art Prints",
        projectSubtitle: "Personal Artwork",
        projectDescription: <div>
            <p>Amidst recovering from injuries, I created some digital pieces where I experimenting with shape and colour. I was then inspired to produce them as physical prints. This meant reworking them to preserve their character and colour in the conversion from RGB to CMYK.</p>
            <p>Wanting to give potential customers an environmentally conscious choice, I endeavoured to ensure that their packaging and collateral (thank you cards, tissue wrap, envelopes and more) were responsibly sourced and eco-friendly where possible, employing home compostable substrates, non-toxic adhesives and vegetable based inks.</p>
        </div>,
        pageDescription: "Amidst recovering from injuries, I created some digital pieces where I experimenting with shape and colour. I was then inspired to produce them as physical prints.",
        projectThumbnail: "/images/thumbnails/prints_thumb.jpg",
        projectURL: "/digital-art-prints",
        projectTags: ["Art Prints", "RGB", "CMYK", "Eco Friendly", "Sustainability", "Circularity"],
        projectImages: [
            {
                imageURL: "/images/prints/prints_aoh_photo_01.jpg",
                imageAlt: "Photograph of Art Print",
                desktopContain: true,
                mobileContain: false
            },
            {
                imageURL: "/images/prints/prints_bml_photo_01.jpg",
                imageAlt: "Photograph of Art Print",
                desktopContain: false,
                mobileContain: false
            },
            {
                imageURL: "/images/prints/prints_eclipse_crop.jpg",
                imageAlt: "Crop of Digital Artwork",
                desktopContain: false,
                mobileContain: true
            },
            {
                imageURL: "/images/prints/prints_mrc_photo_01.jpg",
                imageAlt: "Photograph of Art Print",
                desktopContain: false,
                mobileContain: false
            },
            {
                imageURL: "/images/prints/prints_soc_full.jpg",
                imageAlt: "Digital Artwork",
                desktopContain: true,
                mobileContain: false
            },
            {
                imageURL: "/images/prints/prints_hmp_photo_01.jpg",
                imageAlt: "Photograph of Art Print",
                desktopContain: false,
                mobileContain: false
            }
        ]
    },
    {
        projectTitle: "Finely Diced Logo",
        projectSubtitle: "Yuriosity Games",
        projectDescription: <div>
            <p>Local indie developer Yuriosity Games commissioned me to design a logo for their game <a href="https://store.steampowered.com/app/2357970/Finely_Diced/" target="_blank" rel="noopener noreferrer">Finely Diced<Launch className="project-external-link-icon" /></a> – a roguelite dice-building game with a culinary bent.</p>
            <p>As we developed the logo, they wanted to move away from imagery of knives and instead toward the broader action of cooking. So we incorporated the skillet and tossed dice, giving it a dynamic silhouette that reflects the action of throwing dice in-game. It is set to release in 2025!</p>
        </div>,
        pageDescription: "Local indie developer Yuriosity Games commissioned me to design a logo for their first game: Finely Diced.",
        projectThumbnail: "/images/thumbnails/finely_diced_thumb.jpg",
        projectURL: "/finely-diced",
        projectTags: ["Logo Design", "Indie Game Development", "Illustrator", "Vector"],
        projectImages: [
            {
                imageURL: "/images/finely_diced/finely_diced_development.jpg",
                imageAlt: "Finely Diced Development Sketches and Illustrations",
                desktopContain: true,
                mobileContain: true
            },
            {
                imageURL: "/images/finely_diced/finely_diced_teaser.mp4",
                imageAlt: "Logo Intro Animation from Finely Diced Teaser Trailer",
                desktopContain: false,
                mobileContain: true,
                isVideo: true
            }
        ]
    },
    {
        projectTitle: "Pursuit: COVID-19 Vaccine Special Report",
        projectSubtitle: "University of Melbourne",
        projectDescription: <div>
            <p>As the world fell into lockdown, the media team at <i>Pursuit</i> raced to prepare a report on <a href="https://pursuit.unimelb.edu.au/articles/the-science-behind-the-search-for-a-covid-19-vaccine" target="_blank" rel="noopener noreferrer">the search for a COVID-19 vaccine<Launch className="project-external-link-icon" /></a>. They enlisted our help to generate motion graphics and a novel interactive piece for the five-part report.</p>
            <p>We spent some weeks acquainting ourselves with cellular biology, and drafted scripts with Pursuit's senior editors for the publication's explainer videos, making sure to keep the information as digestible and accurate as possible. By the end, the academics were happy with our comparatively trivial work – let's hope the remaining readership are just as thrilled with the science.</p>
        </div>,
        pageDescription: "As the world fell into lockdown, the media team at Pursuit raced to prepare a report on the search for a COVID-19 vaccine. They enlisted our help to generate motion graphics and a novel interactive piece for the five-part report.",
        projectThumbnail: "/images/thumbnails/unimelb_pursuit_thumb.jpg",
        projectURL: "/unimelb-pursuit",
        projectTags: ["After Effects", "Illustrator", "Animation", "Motion Graphics", "Adobe Media Encoder", "COVID-19", "2020"],
        projectImages: [
            {
                imageURL: "/images/pursuit/pursuit_article_header.jpg",
                imageAlt: "Pursuit Article Header",
                desktopContain: true,
                mobileContain: false
            },
            {
                imageURL: "/images/pursuit/pursuit_article_featured.jpg",
                imageAlt: "Pursuit Article Front Page Feature",
                desktopContain: true,
                mobileContain: true
            },
            {
                imageURL: "/images/pursuit/pursuit_bts_anim.mp4",
                imageAlt: "Pursuit Video Animation Development",
                desktopContain: false,
                mobileContain: false,
                isVideo: true
            },
            {
                imageURL: "/images/pursuit/pursuit_bts_concept.jpg",
                imageAlt: "Pursuit Behind the Scenes Concept",
                desktopContain: false,
                mobileContain: false
            }
        ]
    },
    {
        projectTitle: "University of Melbourne",
        projectSubtitle: "Digital Designer",
        projectDescription: <div>
            <p><i>“The University of Melbourne's websites are our most globally visible asset, attracting <b>27.5 million</b> visitors a year. <b>The Digital and Online Channels (DOC)</b> team is the team responsible for them.”</i> – We printed that in a book <span role="img" aria-label="upside-down smile">🙃</span></p>
            <p>As a part of this illustrious DOC team, I contributed to a number of major projects including: Study Find a Course (FAC) – redesigning sample course plans, course filters and in-page navigation; the university's Okta multi-factor authentication login screens; the events.unimelb LiveWhale site; and more – on top of day-to-day BAU work for our digital design system, and occasional print design for internal documentation.</p>
            {/*<p>I left the team to tend to my health and attribute more time to other pursuits. If you get the opportunity to work with the DOC team, you'll find a place where you can indulge in good humour, and good will.</p>*/}
        </div>,
        pageDescription: "“The University of Melbourne's websites are our most globally visible asset, attracting 27.5 million visitors a year. The Digital and Online Channels (DOC) team is the team responsible for them.” – We printed that in a book.",
        projectThumbnail: "/images/thumbnails/unimelb_doc_thumb.jpg",
        projectURL: "/unimelb",
        projectTags: ["Interaction Design", "User Experience", "UX / UI", "Responsive Design", "WCAG 2.0 AA Accessibility", "Design System", "Figma", "Sketch", "InDesign", "Principle", "Print Design"],
        projectImages: [
            {
                imageURL: "/images/unimelb/unimelb_doc_stat.jpg",
                imageAlt: "Digital Online Channels Booklet Overleaf",
                desktopContain: false,
                mobileContain: false
            },
            {
                imageURL: "/images/unimelb/unimelb_megamenu.jpg",
                imageAlt: "Unimelb Megamenu",
                desktopContain: true,
                mobileContain: true
            },
            {
                imageURL: "/images/unimelb/unimelb_mfa_screens.jpg",
                imageAlt: "Digital Online Channels Booklet Overleaf",
                desktopContain: true,
                mobileContain: true
            },
            {
                imageURL: "/images/unimelb/study_ipnav_dev.jpg",
                imageAlt: "Study Find a Course In-page Navigation Development",
                desktopContain: true,
                mobileContain: true
            },
            {
                imageURL: "/images/unimelb/study_scp_principle.mp4",
                imageAlt: "Study Find a Course In-page Navigation Prototype, made in Principle",
                desktopContain: true,
                mobileContain: true,
                isVideo: true
            },
            {
                imageURL: "/images/unimelb/study_fac_filters.jpg",
                imageAlt: "Study Find a Course Filters UI Overhaul",
                desktopContain: true,
                mobileContain: true
            }/*,
            {
                imageURL: "/images/unimelb/study_scp_semantic.jpg",
                imageAlt: "Study Sample Course Plan Semantic Colourisation",
                desktopContain: true,
                mobileContain: true
            }*/,
            {
                imageURL: "/images/unimelb/unimelb_livewhale_events.jpg",
                imageAlt: "LiveWhale Events Component Samples",
                desktopContain: true,
                mobileContain: true
            },
            {
                imageURL: "/images/unimelb/study_oyl_inside.jpg",
                imageAlt: "Study: One Year Later Booklet",
                desktopContain: false,
                mobileContain: false
            }
        ]
    },
    {
        projectTitle: "Wartide CCG",
        projectSubtitle: "Lachlan Peterson",
        projectDescription: <div>
            <p>I've been brought on to complete a series of illustrations for a collectible indie card game called Wartide. I've composed many of my pieces with an ensemble of figures, using a human frame to create intrigue and dimensionality surrounding the card's object, individual character or event.</p>
        </div>,
        pageDescription: "I've been brought on to complete a series of illustrations for a collectible indie card game called Wartide. I've composed many of my pieces with an ensemble of figures, using a human frame to create intrigue and dimensionality surrounding the card's object, individual character or event.",
        projectThumbnail: "/images/thumbnails/wartide_apstaff_thumb.jpg",
        projectURL: "/wartide",
        projectTags: ["Digital Painting", "Collectible Card Game", "Illustration", "Concept Art"],
        projectImages: [
            {
                imageURL: "/images/wartide/wartide_apstaff.jpg",
                imageAlt: "Wartide - Ancient Priest's Staff",
                desktopContain: true,
                mobileContain: false
            },
            {
                imageURL: "/images/wartide/wartide_vshift.jpg",
                imageAlt: "Wartide - Void Shift",
                desktopContain: true,
                mobileContain: false
            },
            {
                imageURL: "/images/wartide/wartide_soldier_composition.jpg",
                imageAlt: "Wartide - Trench vs Draft Soldier Cards - Using Composition and Lighting to Communicate Conflict and Turmoil",
                desktopContain: true,
                mobileContain: true
            },
            {
                imageURL: "/images/wartide/wartide_soldier_cont.jpg",
                imageAlt: "Wartide - Waltzing Infantry Tank and Airborne Soldier",
                desktopContain: true,
                mobileContain: true
            }
        ]
    },
    {
        projectTitle: "Melbourne Pollen Forecast App",
        projectSubtitle: "University of Melbourne",
        projectDescription: <div>
            <p><i>Melbourne Pollen</i> lets its users keep track of the level of pollen in the air, which is particularly beneficial for those prone to the effects of hay fever, seasonal asthma or other allergic conditions.</p>
            <p>Just before the 2019 bushfires made air quality monitoring an increasing necessity for the general public, the team behind Pollen proposed a new progressive web app (PWA) that would incorporate additional broadcasts and user tools, superseding the existing service. We iterated with the Pollen team (who've been working pro bono!– <a href="https://www.melbournepollen.com.au/who-are-we/meet-team/" target="_blank" rel="noopener noreferrer">here they are<Launch className="project-external-link-icon" /></a>) on modular, adaptable designs to guide the development of their new app.</p>
        </div>,
        pageDescription: "Melbourne Pollen lets its users keep track of the level of pollen in the air, which is particularly beneficial for those prone to the effects of hay fever, seasonal asthma or other allergic conditions.",
        projectThumbnail: "/images/thumbnails/pollen_grid_thumb.jpg",
        projectURL: "/unimelb-pollen",
        projectTags: ["Progressive Web App", "Interaction Design", "User Interaction", "Style Guide", "Pollen"],
        projectImages: [
            {
                imageURL: "/images/pollen/pollen_general.png",
                imageAlt: "Pollen App Preview",
                desktopContain: true,
                mobileContain: true
            },
            {
                imageURL: "/images/pollen/pollen_guide_excerpts.jpg",
                imageAlt: "Pollen Style Guide Excerpts",
                desktopContain: true,
                mobileContain: true
            },
            {
                imageURL: "/images/pollen/pollen_pwa_dvm.jpg",
                imageAlt: "Pollen PWA Desktop vs Mobile",
                desktopContain: true,
                mobileContain: true
            }
        ]
    },
    {
        projectTitle: "Bestfurn Furniture & Bedding",
        projectSubtitle: "Pictoria Media",
        projectDescription: <div>
            <p>Under Pictoria Media, I primarily worked on a bi-monthly catalogue for the <i>Bestfurn Furniture &amp; Bedding Group</i>, consolidating hundreds of media assets, participating in studio shoots – decorating the set, working with resident photographers and a rotation of production staff – in addition to the design of and planning for the catalogue.</p>
        </div>,
        pageDescription: "Under Pictoria Media, I primarily worked on a bi-monthly catalogue for the Bestfurn Furniture & Bedding Group, consolidating hundreds of media assets, participating in studio shoots – decorating the set, working with resident photographers and a rotation of production staff – in addition to the design of and planning for the catalogue.",
        projectThumbnail: "/images/thumbnails/bestfurn_gilmore_thumb.jpg",
        projectURL: "/pictoria-bestfurn",
        projectTags: ["Graphic Design", "Print Design", "CMYK", "Prepress", "Photoshop", "InDesign"],
        projectImages: [
            {
                imageURL: "/images/bestfurn/bestfurn_190304_gilmore.jpg",
                imageAlt: "Bestfurn Gilmore Recliner Suite",
                desktopContain: true,
                mobileContain: false
            },
            {
                imageURL: "/images/bestfurn/bestfurn_diaz_edit.jpg",
                imageAlt: "Bestfurn Diaz Cover Shoot to Asset",
                desktopContain: true,
                mobileContain: true
            },
            {
                imageURL: "/images/bestfurn/bestfurn_190708_spread_a.jpg",
                imageAlt: "Bestfurn July August Winter Spread",
                desktopContain: true,
                mobileContain: true
            },
            {
                imageURL: "/images/bestfurn/bestfurn_181112_shotlist.jpg",
                imageAlt: "Bestfurn Spring Summer Shoot Plan",
                desktopContain: true,
                mobileContain: true
            },
            {
                imageURL: "/images/bestfurn/bestfurn_daisy_hero.jpg",
                imageAlt: "Bestfurn Daisy Hero",
                desktopContain: false,
                mobileContain: false
            },
            {
                imageURL: "/images/bestfurn/bestfurn_190102_spread.jpg",
                imageAlt: "Bestfurn Jan Feb Warehouse Sale Spread",
                desktopContain: true,
                mobileContain: true
            },
            {
                imageURL: "/images/bestfurn/bestfurn_190102_back.jpg",
                imageAlt: "Bestfurn Jan Feb Warehouse Sale Back",
                desktopContain: true,
                mobileContain: true
            },
            {
                imageURL: "/images/bestfurn/bestfurn_190708_front.jpg",
                imageAlt: "Bestfurn Jan Feb Warehouse Sale Back",
                desktopContain: true,
                mobileContain: true
            },
            {
                imageURL: "/images/bestfurn/bestfurn_cooper_mockup.jpg",
                imageAlt: "Cooper Kids Collection Concept to Final Result",
                desktopContain: false,
                mobileContain: false
            }
        ]
    },
    {
        projectTitle: "MLILY Australia",
        projectSubtitle: "Pictoria Media",
        projectDescription: <div>
            <p>One of Pictoria Media's frequent clients <i>MLILY Australia</i>, were preparing for the AIFF (Australian International Furniture Fair ♫) and required video content to showcase their new technologies. I was responsible for storyboarding, animating, editing and compositing 3D visualisations for their Active Touch and Copper Fusion products.</p>
            <p>I also worked on point of sale material (pullup and hang banners, feather flags, window decals, etc.) with which staff could adorn their stores and show off their Mancunian partners.</p>
        </div>,
        pageDescription: "One of Pictoria Media's frequent clients MLILY Australia, were preparing for the AIFF (Australian International Furniture Fair ♫) and required video content to showcase their new technologies. I was responsible for storyboarding, animating, editing and compositing 3D visualisations for their Active Touch and Copper Fusion products.",
        projectThumbnail: "/images/thumbnails/mlily_vfx_thumb.jpg",
        projectURL: "/pictoria-mlily",
        projectTags: ["Graphic Design", "Prepress", "VFX Production", "Animation & Compositing", "POS"],
        projectImages: [
            {
                imageURL: "/images/mlily/mlily_pcm_storyboard.jpg",
                imageAlt: "MLILY PCM Active Touch Technology Storyboard",
                desktopContain: true,
                mobileContain: true
            },
            {
                imageURL: "/images/mlily/mlily_pcm_vfx.jpg",
                imageAlt: "MLILY PCM Active Touch VFX Samples",
                desktopContain: true,
                mobileContain: true
            },
            {
                imageURL: "/images/mlily/mlily_cf_draft_render.mp4",
                imageAlt: "MLILY Copper Fusion Mattress Draft Render",
                desktopContain: true,
                mobileContain: true,
                isVideo: true
            },
            {
                imageURL: "/images/mlily/mlily_pos_context.jpg",
                imageAlt: "MLILY Point of Sale Window Decals and Feather Banner",
                desktopContain: true,
                mobileContain: false
            },
            {
                imageURL: "/images/mlily/mlily_pos_pullup_manu.jpg",
                imageAlt: "MLILY Point of Sale Pullup Banner - Manchester United",
                desktopContain: true,
                mobileContain: true
            },
            {
                imageURL: "/images/mlily/mlily_pos_pullup_wisteria.jpg",
                imageAlt: "MLILY Point of Sale Pullup Banner - Wisteria",
                desktopContain: true,
                mobileContain: true
            }
        ]
    },
    {
        projectTitle: "Fivex Art Prize 2020",
        projectSubtitle: "Digital Billboard Art",
        projectDescription: <div>
            <p>The new annual <a href="https://www.fivexartprize.com.au/gallery/" target="_blank" rel="noopener noreferrer">Fivex Art Prize<Launch className="project-external-link-icon" /></a> intended for a selection of paired artworks to be displayed on LED billboards on the corner of Flinders and Elizabeth streets. My submissions <i>weren't</i> selected, so just think of this as a behind-the-scenes special.</p>
            <p>I conceived these as pieces that might inspire viewers to i. seek out the potential of their world with a quixotic mind; and ii. to find themselves and others in that same world. The swimmer is a figure of individual freedom, and as a diver one of determined curiosity. They navigate the atomic complexity of their surroundings – of which they are made up and in which they are reflected – in an endeavour tempered by an appreciation of their world.</p>
        </div>,
        pageDescription: "The swimmer is a figure of individual freedom, and as a diver one of determined curiosity. They navigate the atomic complexity of their surroundings – of which they are made up and in which they are reflected – in an endeavour tempered by an appreciation of their world.",

        projectThumbnail: "/images/thumbnails/fivex_podium_thumb.jpg",
        projectURL: "/fivex-prize",
        projectTags: ["Digital Painting", "LED billboard", "Flinders St", "Elizabeth St", "QMS Media"],
        projectImages: [
            {
                imageURL: "/images/fivex/fivex_fb_wrap.jpg",
                imageAlt: "Fivex Art Prize Billboard Wrap Artwork",
                desktopContain: false,
                mobileContain: true
            },
            {
                imageURL: "/images/fivex/fivex_fb_podium.jpg",
                imageAlt: "Fivex Art Prize Billboard Podium Artwork",
                desktopContain: true,
                mobileContain: true
            }
        ]
    },
    {
        projectTitle: "Evans & Partners",
        projectSubtitle: "Algo Trading Services",
        projectDescription: <div>
            <p>The stock-broking solutions firm (then transitioning from <i>Evans &amp; Peters</i>) hired Algo Trading Services to produce an administration portal for staff members, and they required a design overhaul.</p>
            <p>I prepared a series of visual guides, each containing applications of my new designs and notes on appropriate styling, visual indicators, and form flow– making interactions meaningful and clear, and all in keeping with the established brand identity of Evans &amp; Partners.</p>
        </div>,
        pageDescription: "The stock-broking solutions firm (then transitioning from Evans & Peters) hired Algo Trading Services to produce an administration portal for staff members, and they required a design overhaul.",

        projectThumbnail: "/images/thumbnails/enp_guide_thumb.jpg",
        projectURL: "/enp",
        projectTags: ["Graphic Design", "Interaction Design", "UX / UI"],
        projectImages: [
            {
                imageURL: "/images/enp/enp_cover.jpg",
                imageAlt: "Evans and Peters Cover",
                desktopContain: false,
                mobileContain: false
            }
        ]
    },
    {
        projectTitle: "Master Portrait Studies",
        projectSubtitle: "Personal Work",
        projectDescription: <div>
            <p>Master studies are one of my favourite ways to improve my drawing/painting. It's helpful for gaining insight into how to simplify and capture a potential subject, while honing your mechanics through the reproduction. A fun way to see how you went is to run a <a href="https://lens.google.com/uploadbyurl?url=https://jlliew.com/images/masters/jpulitzer_jss.jpg" target="_blank" rel="noopener noreferrer">reverse Google image search<Launch className="project-external-link-icon" /></a> – you know you've done alright when it directs you to the original!</p>
            <p>Original works by: <i>John Singer Sargent, Zhaoming Wu and Brian Huang.</i></p>
        </div>,
        pageDescription: "Master studies are one of my favourite ways to improve my drawing/painting. It's helpful for gaining insight into how to simplify and capture a potential subject, while honing your mechanics through the reproduction.",

        projectThumbnail: "/images/thumbnails/ms_joseph_thumb.jpg",
        projectURL: "/master-studies",
        projectTags: ["Master Studies", "Digital Painting", "Portrait Art"],
        projectImages: [
            {
                imageURL: "/images/masters/jpulitzer_jss.jpg",
                imageAlt: "Joseph Pulitzer Master Study (original by John Singer Sargent)",
                desktopContain: true,
                mobileContain: false
            },
            {
                imageURL: "/images/masters/egypt_jss.jpg",
                imageAlt: "Egyptian Woman Master Study (original by John Singer Sargent)",
                desktopContain: true,
                mobileContain: false
            },
            {
                imageURL: "/images/masters/rebecca_zw.jpg",
                imageAlt: "Rebecca Master Study (original by Zhaoming Wu)",
                desktopContain: true,
                mobileContain: false
            },
            {
                imageURL: "/images/masters/hydra_bh.jpg",
                imageAlt: "Hydralisk Master Study (original by Brian Huang)",
                desktopContain: true,
                mobileContain: false
            },
            {
                imageURL: "/images/masters/henry_jss.jpg",
                imageAlt: "Sir Henry Hughes Wilson Master Study (original by John Singer Sargent)",
                desktopContain: true,
                mobileContain: false
            },
            {
                imageURL: "/images/masters/joachim_jss.jpg",
                imageAlt: "Joseph Joachim Master Study (original by Joseph Singer Sargent)",
                desktopContain: true,
                mobileContain: true
            },
            {
                imageURL: "/images/masters/captain_zw.jpg",
                imageAlt: "Captain Master Study (original by Zhaoming Wu)",
                desktopContain: true,
                mobileContain: false
            }
        ]
    },

    {
        projectTitle: "Bachelor of Design – Games",
        projectSubtitle: "RMIT University",
        projectDescription: <div>
            <p>Even though I specialised as a 2D artist, this program's emphasis on independent game development taught us how to work with object-oriented programming languages (such as C#), as well as how to sculpt, rig and animate 3D models.</p>
            <p>Working collaboratively, I contributed concept art, generated 3D assets, produced team documents and presentations, consolidated the team's assets for implementation, and assisted our lead programmer with a dash of code. This cultivated a hollistic practice that has continually prepared me for so many industry-adjacent challenges.</p>
            {/*<p><a href="https://youtu.be/A8NFMhy85SE" target="_blank" rel="noopener noreferrer">Check out this trailer for one of our projects, <i>Red Country!</i><Launch className="project-external-link-icon" /></a></p>*/}
        </div>,
        pageDescription: "Even though I specialised as a 2D artist, this program's emphasis on independent game development also had me learning how to work with object-oriented programming languages (such as C#), as well as how to sculpt, rig and animate 3D models.",

        projectThumbnail: "/images/thumbnails/gdesign_thumb.jpg",
        projectURL: "/games-design",
        projectTags: ["Concept Art", "3D Modelling", "Low / High Poly", "Animation", "Object-oriented Programming", "Maya", "Unity", "A/V Production"],
        projectImages: [
            {
                imageURL: "/images/gdesign/dg_sheet_a.jpg",
                imageAlt: "Western Fantasy Character Concept Excerpts",
                desktopContain: true,
                mobileContain: false
            },
            {
                imageURL: "/images/gdesign/dg_sheet_b.jpg",
                imageAlt: "Western Fantasy Character Concept Excerpts",
                desktopContain: true,
                mobileContain: false
            },
            {
                imageURL: "/images/gdesign/venv_cloister.jpg",
                imageAlt: "Virtual Environments Cloister Samples",
                desktopContain: false,
                mobileContain: false
            },
            {
                imageURL: "/images/gdesign/ca_morpheus.jpg",
                imageAlt: "'An Approach to Death' Artwork",
                desktopContain: true,
                mobileContain: false
            },
            {
                imageURL: "/images/gdesign/rc_samples.jpg",
                imageAlt: "'Red Country' Online Multiplayer Horror Samples",
                desktopContain: true,
                mobileContain: true
            },
            {
                imageURL: "/images/gdesign/dim_wtsrkkptttdg.jpg",
                imageAlt: "Design for Interactive Media Prototype Samples",
                desktopContain: true,
                mobileContain: true
            },
            {
                imageURL: "/images/gdesign/ivg_nldgclba.jpg",
                imageAlt: "Independent Videogames Prototype Development",
                desktopContain: true,
                mobileContain: true
            },
            {
                imageURL: "/images/gdesign/idw_sketches.jpg",
                imageAlt: "Underwater Horror/Adventure Concept Sketches",
                desktopContain: false,
                mobileContain: true
            },
            {
                imageURL: "/images/gdesign/tanner_posters.jpg",
                imageAlt: "'Tanner' Diegetic Posters",
                desktopContain: false,
                mobileContain: false
            }
            /*{
                imageURL: <iframe width="100%" height="100%" src="https://www.youtube.com/embed/A8NFMhy85SE" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>,
                imageAlt: "Red Country Trailer",
                desktopContain: true,
                mobileContain: false,
                isCustom: true
            }*/
        ]
    }
];