import React from 'react';
import './ProjectTiles.css';
import { NavLink } from 'react-router-dom';

class ProjectTiles extends React.Component {

  render() {

    const tileCount = Object.keys(this.props.projectTileData).length;
    const standardRowCount = this.props.standardTileRowCount;
    const unevenRowRemainder = tileCount % standardRowCount;
    const centerLastRow = ((unevenRowRemainder > 0) && (unevenRowRemainder % 2 === 0));

    /*
    console.log("There are " + tileCount + " tiles.")
    console.log("On desktop, a standard row should include " + standardRowCount + " tiles.")
    console.log("The last row will have " + unevenRowRemainder + " tile(s).")
    console.log("Because the last row is " + (centerLastRow ? "even" : "odd") +
       (centerLastRow ? ", we will centre the last row." : ",  we will align the last row to the start.") 
    )*/

    return (
      <div className="project-tiles"
        style={{
          justifyContent: centerLastRow ? 'center' : 'flex-start'
        }}
      >

        {this.props.projectTileData.map((tile, index) => {
          return <NavLink to={tile[2]} className="project-tile"
            onClick={() => {
              this.props.openOverlay();
              this.props.setActiveProject(index);
            }}
            key={index} title={tile[1]}>
            <img className="tile-image" src={tile[0]} alt={tile[1]} />
          </NavLink>;
        })}
      </div>
    );
  }
}

export default ProjectTiles;