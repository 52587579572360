import React from 'react' // React must be in scope to use JSX;
import Launch from '@material-ui/icons/Launch';
import Instagram from '@material-ui/icons/Instagram';


export const randomotronItems =  [    
    /*((<span>
        I'm working up to doing <a className="link" target="_blank" title="Suspiria Sabbath Study" href="/images/randomotron/suspiria_sabbath_study_bw.jpg">
            daily studies<Launch className="randomotron-icon"/></a>
    </span>)),*/
    ((<span>
        <a className="link" target="_blank" title="Instagram: jlliew.art" rel="noopener noreferrer"  href="https://www.instagram.com/jlliew.art/">
        Instagram <Instagram className="randomotron-icon"/></a>
    </span>)),
    /*(<span>Some sound dishes I've been enjoying: <a className="link" target="_blank" title="Spotify playlist: Heidelberg Hymnary" rel="noopener noreferrer" href="https://open.spotify.com/playlist/2422RGEkrULJt2cVXEBg9t">
    Spotify ♫</a></span>),*/
    (<span><a className="link" target="_blank" title="Spotify playlist: Arthritic Ambulation" rel="noopener noreferrer" href="https://open.spotify.com/playlist/64WNd8tMdHJr9KNo9J7gBK">♫</a></span>)/*,
    (<span>Some sound dishes I've been enjoying: <a className="link" target="_blank" title="Spotify playlist: Arthritic Ambulation" rel="noopener noreferrer" href="https://open.spotify.com/playlist/1SgIvAqKlqtTjOO4fiG5GL">
    Spotify ♫</a></span>)/*,
    (<span>
        Here's an <a className="link" target="_blank" title="Buttered Prawn with Parsley" href="/images/redbubble/foods_that_dont_exist.jpg">
            inaugural sketch<Launch className="randomotron-icon"/></a> for a series of <i>Foods that Don't Exist</i>.
    </span>)*/
    /*,
    (<div>Cooking tip #14: if uncertain, add <i>more</i> butter.</div>) 
    (<span>
        This is my favourite <a className="link" target="_blank" title="Lómagnú with sand blowing over Skeiðarársandur" rel="noopener noreferrer"  href="https://upload.wikimedia.org/wikipedia/commons/a/a4/L%C3%B3magn%C3%BA_with_sand_blowing.jpg">
            big rock<Launch className="randomotron-icon"/></a>.
    </span>)*/
];